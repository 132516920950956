html, body
{
	margin: 0;
	padding: 0;
	background-color: #FEFCF9;
	& *
	{
		box-sizing: border-box; //very important!
	}
	a
	{
		text-decoration: unset;
	}
}

* //necessary to override user agent style for button, which sets arial as font
{
	font-family: 'Roboto', sans-serif;
}

