
.header
{
	background-image: url('../../../img/klaus.png'), url('../../../img/landing-title-background.png');
	background-size: contain, cover;
	background-repeat: no-repeat;
	background-position: right 50vh, top left;
	height: 100vh;
	@media screen and (min-width: 900px)
	{
		background-size: contain, cover;
		background-repeat: no-repeat;
		background-position: right, top left;
	}
}

.columnImg {
  max-height: 26rem;
}
.dashScreenshotImg {
  box-shadow: 1px 1px 3px #888;
  max-height: 400px;
}