.headerMargin { margin: 2.5rem 0; }
.bold { font-weight: 700; }

.columnImg { max-height: 30rem }
.dashboardScreenshotImg {
  box-shadow: 1px 1px 3px #888;
  max-height: 350px;
}

.sectionPadded {
  padding: 0 2rem;
}

.listWrap {
  text-align: initial;
  ul {
    list-style-type: none;
    li:before {
      content: '✓';
      color: #4BB543;
      font-size: 2.5rem;
      margin-right: 1rem;
      vertical-align: -.5rem;
    }
  }
}

.signature {
  font-style: italic;
}