$desktop: 600px; //TODO: set back to 1200px, current valie is for testing purposes //1200px;
$color_menu: #FFF9EE;
.nav
{
	flex-shrink: 0;
	position: sticky;
	top: 0;
	height: 100vh;
	background-color: $color_menu;
	padding: 8px;
	border-right: 2px solid #E5E5E5;
	box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.45);
	a
	{
		text-decoration: none;
	}
	//width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media screen and (min-width: $desktop)
	{
		min-height: 100vh;
		//width: 380px;
	}
	@media print
	{
		display: none;
	}
}